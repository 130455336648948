import './focus.scss';

import { scrollTo } from '../../utils/functions';

export default function Focus() {
	return (
		<section id='focus' className='focus__section'>
			<div className='focus__wrapper main-grid'>
				<h2>
					Tudo é sobre <strong>FOCAR NO 1BI.</strong>
					<span className='h2_hr'></span>
				</h2>
				<p>
					Por isso, abrimos espaço para <strong>TODOS OS NSTECHERS</strong> do
					ecossistema formarem equipes e pensarem em como podemos melhorar a
					empresa, pensando em formas de{' '}
					<strong>aumentar receita, otimizar processos</strong> ou{' '}
					<strong>reduzir custos</strong> usando tecnologia para atingir ao
					menos 1 desses 3 objetivos.
				</p>
				<a
					className='button--primary--lg button'
					onClick={() => {
						scrollTo('subscribe');
					}}
				>
					Inscreva-se
				</a>
				<img
					className='car'
					src='/images/car.png'
					alt='Imagem da campanha: Foca no Bi'
				/>
			</div>
		</section>
	);
}
