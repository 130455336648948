import React from 'react';

import './App.scss';

import Header from './components/Header';
import Hero from './components/Hero';
import Focus from './components/Focus';
import When from './components/When';
import Mentors from './components/Mentors';
import Subscribe from './components/Subscribe';
import Prizes from './components/Prizes';
import Footer from './components/Footer';

function App() {
	return (
		<>
			<Header />
			<main style={{ marginBottom: 300, backgroundColor: 'white' }}>
				<Hero />
				<When />
				<Focus />
				<Mentors />
				{/* <Subscribe /> */}
				{/* <Prizes /> */}
				{/* <div style={{ height: '300px' }}></div> */}
			</main>
			<Footer />
		</>
	);
}

export default App;
