import './when.scss';

export default function When() {
	return (
		<section id='when' className='when__section'>
			<div className='main-grid'>
				<h2>
					Quando e <strong>onde?</strong>
					<span className='h2_hr'></span>
				</h2>
				<p className='date'>
					<strong>XX e YY de XXXXXX</strong>
				</p>
				<div className='online'>
					<p className='title'>Online</p>
					<p>No Discord</p>
					<img src='/images/logo_discord.png' alt='logo discord' />
				</div>
				<div className='in-person'>
					<p className='title'>Presencial</p>
					<p>nos escritórios:</p>
					<ul>
						<li>
							<strong style={{ color: 'white' }}>São Paulo</strong>
						</li>
						<li>
							<strong style={{ color: 'white' }}>Chapecó</strong>
						</li>
						<li>
							<strong style={{ color: 'white' }}>Ponta Grossa</strong>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}
