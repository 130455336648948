import './footer.scss';

export default function When() {
	return (
		<footer className='footer__section'>
			<a href='http://www.nstech.com.br' target='_blank'>
				<img src='./images/logo_footer.png' />
			</a>
		</footer>
	);
}
