import './mentors_grid.scss';

export default function MentorsGrid({ data }) {
	return (
		<ul className='info_grid'>
			{data.map((item) => (
				<li key={item.name}>
					<div
						className='image_cover'
						style={{
							backgroundImage: `url(${item.image})`,
						}}
					/>
					<div className='infos'>
						<a
							className='mentor__name'
							href={item.linkedin}
							rel='noreferrer'
							target='_blank'
						>
							<img
								className='logo_linkedin'
								src='./images/logo_linkedin.png'
								alt='Logo LinkeIn'
							/>
							<h3>{item.name}</h3>
						</a>
						<p>{item.description}</p>
					</div>
					<div
						className='image_background'
						style={{
							backgroundImage: `url(${item.image})`,
						}}
					/>
				</li>
			))}
		</ul>
	);
}
