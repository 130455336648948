import MentorsGrid from '../MentorsGrid';

import './mentors.scss';
import mentorsList from '../../assets/data/mentors.json';

export default function Mentors() {
	return (
		<section id='mentors' className='mentors__section'>
			<div className='main-grid'>
				<h2>
					Conheça nossos <strong>mentores</strong>
					<span className='h2_hr ' />
				</h2>
			</div>
			<MentorsGrid data={mentorsList} />
			{/* <p className='text'>
				Quer ser um mentor no hackathon?{' '}
				<a
					className='button--primary--sm'
					href='mailto:hackathon@nstech.com.br'
				>
					Entre em contato com a gente
				</a>
			</p> */}
		</section>
	);
}
