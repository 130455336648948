import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { collection, addDoc, getDocs } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addAtendee = async (atendeeData) => {
	// Check if atendee already exists
	const atendeesList = await getAtendees();
	atendeeData.timeStamp = serverTimestamp();

	let existingAtendee = atendeesList.find((item) => {
		return item.cpf === atendeeData.cpf;
	});

	if (existingAtendee) {
		return {
			error: 'Participante já cadastrado',
			id: existingAtendee.id,
		};
	}

	// If atendee don't exists, try to add
	if (
		atendeeData.name &&
		atendeeData.email &&
		atendeeData.cpf &&
		atendeeData.location &&
		atendeeData.workAt &&
		atendeeData.workAt !== 'Selecione um local' &&
		atendeeData.workWith.length !== 0
	) {
		atendeeData.position = atendeesList.length + 1;
		return await addDoc(collection(db, 'atendees'), atendeeData);
	}

	return { error: 'Dados do usuário incompletos' };
};

export const getAtendees = async () => {
	let atendees = [];

	await getDocs(collection(db, 'atendees')).then((data) => {
		data.forEach((doc) => {
			atendees.push({ id: doc.id, ...doc.data() });
		});
	});

	atendees = atendees.sort((a, b) => {
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();

		if (nameA < nameB) {
			return -1;
		}

		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});

	return atendees;
};

export const addTeam = async (teamData) => {
	const teamsList = await getTeams();

	// check if team members already in another team
	let isAtendeesAlreadyInATeam = false;

	let atendeesInATeam = [];

	let name;

	teamData.timeStamp = serverTimestamp();
	teamData.atendees.forEach(async (atendee1, i) => {
		teamsList.forEach((team, j) => {
			team.atendees.forEach(async (atendee2, k) => {
				if (atendee1 === atendee2) {
					isAtendeesAlreadyInATeam = true;

					atendeesInATeam.push(atendee1);

					return true;
				}
			});
		});
	});

	if (atendeesInATeam.length > 0) {
		return {
			error: 'Algum participante já está em outra equipe',
			atendeesInATeam,
		};
	}

	if (teamData.atendees.length < 3) {
		return {
			error: 'A equipe deve ter de 3 a 5 participantes',
		};
	}

	return await addDoc(collection(db, 'teams'), teamData);
};

export const getTeams = async () => {
	const querySnapshot = await getDocs(collection(db, 'teams'));
	const teams = [];

	querySnapshot.forEach((doc) => {
		teams.push({ id: doc.id, ...doc.data() });
	});

	return teams;
};

export const getAtendeeNameById = async (id) => {
	const atendeesList = await getAtendees();

	let query = null;

	atendeesList.forEach((atendee) => {
		if (atendee.id === id) {
			query = atendee;
		}
	});

	return query;
};
