import './hero.scss';

export default function Header() {
	return (
		<section className='hero__section'>
			<div className='main-grid'>
				<h2>
					<span>
						Pensando em <strong>soluções</strong>,
					</span>
					<span>competindo pela inovação.</span>
					<span className='h2_hr'></span>
				</h2>
				<p>
					Participe da <strong>1ª edição</strong> do nstech hackathon, ajude a
					melhorar nossa empresa, ao mesmo tempo que você e sua equipe concorrem
					a mais de <strong>R$30.000 em prêmios</strong>.
				</p>
				<br />
				<p>
					No dia XX e YY de XXXXXXX, nos mostre seu projeto que vai ajudar a
					nstech a crescer, <strong>aumentando a receita</strong> ou{' '}
					<strong>melhorando nossos produtos</strong>.
				</p>
				<br />
				<p>Veja mais detalhes abaixo</p>
			</div>
		</section>
	);
}
