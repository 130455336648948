import './header.scss';

import logoNstech from '../../assets/images/logo_nstech.svg';
import logoHackathon from '../../assets/images/logo_hackathon.png';
import { scrollTo } from '../../utils/functions';

export default function Header() {
	return (
		<>
			<header className='header__session'>
				<div className='main-grid header__content'>
					<div className='h1__content'>
						<h1>
							<img src={logoNstech} className='logo_nstech' alt='logo nstech' />
							<img
								src={logoHackathon}
								className='logo_hackathon'
								alt='logo hackathon'
							/>
						</h1>
						{/* <span className='event_date'>16 e 17 de agosto</span> */}
					</div>
					<div className='menu__wrapper'>
						<ul>
							<li
								onClick={() => {
									scrollTo('focus');
								}}
							>
								<a className='button--secondary'>Sobre</a>
							</li>
							<li
								onClick={() => {
									scrollTo('when');
								}}
							>
								<a className='button--secondary'>Quando e onde</a>
							</li>
							<li
								onClick={() => {
									scrollTo('mentors');
								}}
							>
								<a className='button--secondary'>Mentores</a>
							</li>
							<li
								onClick={() => {
									scrollTo('prizes');
								}}
							>
								<a className='button--secondary'>Premiação</a>
							</li>
							{/* <li>
								<a className='button--secondary'>Regras</a>
							</li> */}
						</ul>
						{/* <a
							className='button--primary'
							onClick={() => {
								scrollTo('subscribe');
							}}
						>
							Inscreva-se
						</a> */}
					</div>
				</div>
			</header>
		</>
	);
}
